/* src/pages/AuthorsDetailPage.css */
.card-img-wrapper {
    height: 200px; /* 画像の表示高さ */
    display: flex;
    justify-content: center;
    align-items: center;
    overflow: hidden; /* 画像がカードからはみ出さないようにする */
  }
  
  .card-img-wrapper img {
    height: 100%; /* 高さを親要素に合わせる */
    width: auto; /* 幅は自動調整 */
    object-fit: contain; /* 画像の縦横比を維持して、カード内に収まるように調整 */
  }
  
  .card {
    border: 1px solid rgba(0, 0, 0, 0.12); /* 境界線 */
    transition: box-shadow 0.3s; /* 影の変化にアニメーションを追加 */
  }
  
  .card:hover {
    box-shadow: 0px 4px 20px rgba(0, 0, 0, 0.3); /* ホバー時の影を強くする */
  }
  